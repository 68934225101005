/* Home page */

import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import Layout from '../components/Layout';

export default function Home(props) {
  const ctx = props.pageContext;
  const sitedata = props.data.siteYaml;
  sitedata.language = ctx.locale;
  const bannerImages = props.data.bannerImages.edges.map(edge => edge.node)
    .filter(img => img.name===sitedata.bannerImage || img.name===`${sitedata.bannerImage}-mobile`);
  sitedata.clinics = props.data.siteLocations.addresses;

  return (
    <Layout config={sitedata} ctx={ctx} currentpath={props.path} bannerImages={bannerImages}>
      <Helmet htmlAttributes={{lang: ctx.locale}}>
        <meta charSet="utf-8" />
        <title>{sitedata.sitename}</title>
        <link rel="canonical" href={sitedata.siteurl} />
        <meta name="description" content={sitedata.metaDescription}></meta>
        <meta name="facebook-domain-verification" content="1cduva45gempno9yr93rxy729viyjc" />
      </Helmet>
    </Layout>
  );
};

export const query = graphql`
  query siteLocaleQ($locale: String, $variation: String) {
    siteYaml(locale: {eq: $locale}, variation: {eq: $variation}) {
      locale
      variation
      homelink
      fixedCta
      sitename
      siteurl
      startstudy
      canCollectInfo
      bannerImage
      title
      subtitle
      metaDescription
      surveyprompt
      sections {
        theme
        id
        linkto
        title
        header
        headerclass
        headerbg
        text
        data {
          content
          items
        }
      }
      patientFields {
        address
        cta
        ctaDq
        email
        firstname
        lastname
        phone
        referredbyDefault
        referredby {
          id
          value
        }
        othersDefault
        others {
          id
          value
        }
        agegroupDefault
        agegroup
        criteria {
          label
          items
        }
      }
      footer {
        text
        note
        links {
          label
          to
        }
      }
      errorpage {
        header
        subheader
      }
      screener {
        questions {
          q
          proceedIfYes
          theme
          header
        }
        questionLabel {
          affirm
          deny
          next
          noun
          prep
        }
        introSlide {
          header
          text
          footer
        }
        successResult {
          header
          message
          thankyou {
            bg
            header
            subWLocation
            subheader
            text
          }
          summary {
            header
            text
          }
        }
        failResult {
          header
          message
          noform {
            message
          }
          thankyou {
            bg
            header
            subheader
            text
          }
          summary {
            header
            text
          }
        }
        formFootnote
        locations {
          cta
          ctabutton
          header
          noSuitableSite
          noform {
            cta
            text
          }
        }
      }
    }
    bannerImages: allFile(filter: {relativeDirectory: {eq: "banners"}}) {
      edges {
        node {
          name
          base
          childImageSharp {
            gatsbyImageData(
              formats: [AUTO, WEBP, AVIF]
              placeholder: BLURRED
              layout: CONSTRAINED
              width: 1400
            )
          }
        }
      }
    }
    siteLocations: dataYaml(locale: {eq: $locale}) {
      addresses {
        name
        street
        city
        state
        zip
        lat
        lng
      }
    }
  }`;
