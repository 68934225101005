/**
 * Banner provides a container with a title, a button, and an image.
 * Supported props: src siteurl toplinks localizerfn, bannerImages, navHandler
 */
import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Safe from './Safe';
import { startScreenerGA } from '../util/pagetools';
import logo from '../images/logo.png';
import pfizerLogo from '../images/pfizer-logo.png';

export default function Banner(props) {
  const localized = props.localizerfn;
  const cfg = props.src;
  const mainBanner = getImage(props.bannerImages.find(img => img.name.indexOf('mobile')<0));
  const mobileBanner = getImage(props.bannerImages.find(img => img.name.indexOf('mobile')>0));
  const visStyle = (props.usLocaleOnly ? '' : 'hid');
  const topLogo = (props.usLocaleOnly ? pfizerLogo : logo);

  return (
    <div id="banner" className="row banner bg-forest posrel">
      <div className="col-xs-12 col-sm-6">
        <div className="banner-left-content">
          <div id="logo">
            <Link to={localized('/')} title="Home">
              <img src={topLogo} alt={props.siteurl} className="img-responsive" />
            </Link>
          </div>
          <Safe className="banner-title headline-font up" type="h1" content={cfg.title} />
          <Safe className="banner-surveyprompt" content={cfg.surveyprompt} />
        </div>
      </div>
      <div className="col-xs-12 col-sm-6 banner-img-container posrel">
        <GatsbyImage image={mainBanner} alt="main banner" className="hidden-xs" />
        <GatsbyImage image={mobileBanner} alt="mobile banner" className="visible-xs" />
        <img src={logo} alt={props.siteurl} className={setStyle('se img-responsive', visStyle)} />
      </div>
    </div>
  );
};

function setStyle(base, supplemental) { return `${base} ${supplemental}`.trim(); }
