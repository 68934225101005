/**
 * Layout is a default page wrapper defining the major sections of the site.
 * Supported props: config, ctx, currentpath, bannerImages
 */

import React, { useState } from 'react';
import CookieBanner from './CookieBanner';
import Banner from './BannerLogo';
import Footer from './FooterSimple';
import { makeTopLinks } from '../util/pagetools';
import { localizer } from '../util/localizer';

export default function Layout(props) {
  const [openLinks, setOpenLinks] = useState(false);
  // As workaround for bootstrap, hide an element given a min-height of 1px by making bg transparent
  const cfg = props.config;
  const bannerdata = {
    title: cfg.title,
    subtitle: cfg.subtitle,
    surveyprompt: cfg.surveyprompt,
    startstudy: cfg.startstudy,
    ctx: props.ctx,
    currentpath: props.currentpath
  }
  const localized = localizer(props.ctx);
  const toplinks = makeTopLinks(cfg);
  const footdata = {
    ...cfg.footer,
    toplinks,
    sitename: cfg.sitename,
    siteurl: cfg.siteurl
  };
  const usLocaleOnly = (props.ctx.locale==='en' || props.ctx.locale==='es-us');
  function openMenu(e) {
    const isExpanded = !openLinks;
    let targetElem = e.target;
    if (targetElem.tagName!=='BUTTON') targetElem = targetElem.closest('BUTTON');
    if (isExpanded) targetElem.classList.add('open');
    else            targetElem.classList.remove('open');
    setOpenLinks(!openLinks);
  }

  return (
    <div id="page">
      <div id="layout-root" className={`locale-${props.ctx.locale}`}>
        <header>
          <Banner src={bannerdata} siteurl={cfg.siteurl}
                  toplinks={toplinks}
                  localizerfn={localized}
                  bannerImages={props.bannerImages}
                  usLocaleOnly={usLocaleOnly}
                  navHandler={openMenu} />
        </header>
        {props.children}
        <Footer src={footdata} localizerfn={localized} usLocaleOnly={usLocaleOnly} />
      </div>
      <CookieBanner />
    </div>
  );
};
